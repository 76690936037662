var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100",
    staticStyle: {
      "background-image": "url(/images/header.png)"
    }
  }), _c('section', {
    staticClass: "section d-flex align-items-center"
  }, [_c('div', {
    staticClass: "container",
    staticStyle: {
      "padding-right": "100px",
      "padding-left": "100px",
      "height": "inherit"
    }
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "card bg-light rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_vm._m(0), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-3"
  }, [_c('div', {
    staticClass: "form-group position-relative mt-4"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("pls-enter-the-coupon")))])])]), _c('div', {
    staticClass: "col-lg-7"
  }, [_c('div', {
    staticClass: "form-group position-relative mt-4"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.insert_code,
      expression: "insert_code"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "coupon",
      "required": ""
    },
    domProps: {
      "value": _vm.insert_code
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.insert_code = $event.target.value;
      }
    }
  })])]), _c('div', {
    staticClass: "col-lg-2"
  }, [_c('div', {
    staticClass: "form-group position-relative mt-4"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("redeem")) + " ")])])])])])])])])])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('h5', {
    staticClass: "text-center"
  }, [_vm._v("Hibah One Cent")])])]);
}]

export { render, staticRenderFns }